import React, { useEffect, useState }  from 'react';
import {Link} from 'react-router-dom';
import logo from '../assets/imgs/logo.png';
import {IonBadge,IonIcon,IonListHeader, IonInput, IonButton} from '@ionic/react';
import {cartSharp,searchSharp, callOutline, mailOutline, logoFacebook, logoInstagram, logoYoutube} from 'ionicons/icons';
import {user} from '../services/user';

import {usePageContext,useStoreContext} from '../store/';
import {menuController} from '@ionic/core';

import {useHistory} from 'react-router-dom';
import u from '../services/util';


 
const WebHeader:React.FC = (props:any)=>{
    const {state,dispatch}  = useStoreContext();
    const page = usePageContext();
    const history = useHistory();
    const [search,setSearch] = useState<string>("");

    let hideTimeout:any;
    useEffect(()=>{
        document.querySelectorAll('.main-nav a').forEach((el:any)=>{
            let content = el.getAttribute('content');
            if(content){
                el.onclick = ()=>{
                    history.push(content);
                }
            }
        });

        document.querySelectorAll(".mega-menu").forEach((el:any)=>{
            let m_id = el.getAttribute('menu-id');
            el.addEventListener("mouseenter", ()=>{
                let c_el:any = document.querySelector(`#mega-menu-${m_id}`);
                if(c_el){
                    c_el.style.display='block';
                }
            });
            el.addEventListener("mouseleave", ()=>{
                let c_el:any = document.querySelector(`#mega-menu-${m_id}`);
                hideTimeout = setTimeout(()=>{
                    c_el.style.display='none';
                }, 300); // Delayed hide
            }); 
        });

        document.querySelectorAll(".mega-menu-content,.mega-menu-wrapper").forEach((el:any)=>{
            let id = el.getAttribute('id');
            el.addEventListener("mouseenter", ()=>{
                clearTimeout(hideTimeout);
            });
            el.addEventListener("mouseleave", ()=>{
                let c_el:any = document.querySelector(`#${id}`);
                if(c_el){
                    c_el.style.display='none';
                }
            }); 
        });


    },[state])


    const handlerSearchChange = (e:any)=>{
        setSearch(e.target.value)
    }
    const handlerSearch = (e:any)=>{
        if(e.keyCode){
            if(e.keyCode && e.keyCode == 13){
                do_search();
            }
        }else{
            do_search();  
        }
    }
    const do_search = ()=>{
        let term = search.trim();
        if(term.length > 0){
            term = `${term}`
            let url = `/search#Name=${term}&Description=${term}&Category=${term}&Class=${term}&Type=${term}&SubType=${term}&SKU=${term}`;
            history.push(url);
        }
    }

    

    return (
        <div className="ion-hide-md-down">
            <div className="web-header">
                <div className='top-bar'>
                    <div className='web-width'>
                        <div className="menu">
                            <ul>
                                <li><Link to="/aboutus">About Us</Link></li>
                                <li><Link to="/contactus">Contact Us</Link></li>
                                {(state.user.isLogin)?
                                    <>
                                        <li style={{'display':'none'}}><Link to="/quickOrder">Quick Orders</Link> </li>
                                        <li><Link to="/myAccount">My Account</Link></li>
                                        {(state.user.isLogin && state.user.role=='admin')?
                                            <>
                                            <li><Link to="/admin">Admin</Link></li>
                                            </>
                                        :''}
                                        <li><a onClick={()=>{ dispatch({type:'logout'})  }}>Sign Out</a></li>
                                    </>
                                    : 
                                    <>
                                        <li><Link to="/login">Login</Link></li>
                                        <li><Link to="/registration">Sign Up</Link></li>
                                    </>    
                                }
                            </ul>
                        </div>
                        <div className="social">
                            <a href="https://www.facebook.com/Meraki-Gemstones-102098801135557" target="_blank" rel="noopener noreferrer"><IonIcon icon={logoFacebook}></IonIcon></a>
                            <a href="https://www.instagram.com/merakigemstones/" target="_blank" rel="noopener noreferrer"><IonIcon icon={logoInstagram}></IonIcon></a>
                            <a href="https://www.youtube.com/channel/UCQZqZwQJcQFPgutR7B9lrzA" target="_blank" rel="noopener noreferrer"><IonIcon icon={logoYoutube}></IonIcon></a>
                        </div>
                    </div>
                </div>
                <div className='middle-bar'>
                    <div className='web-width'>
                        <div className="logo">
                            <Link to="/"><img src={logo}></img></Link>
                        </div>
                        <div className="search" >
                            <IonInput 
                                clearInput={false}
                                value={search}
                                placeholder="Search"
                                maxlength={50}
                                onIonChange={handlerSearchChange}
                                onKeyDown={handlerSearch}
                            ></IonInput>
                            <IonIcon icon={searchSharp} onClick={handlerSearch} />
                        </div>
                        <div className="top-cart" onClick={()=>{menuController.open("menu-cart")}}>
                            <IonIcon icon={cartSharp}></IonIcon>
                            <span>{state.cart.length}</span>
                        </div>
                    </div>
                </div>
                
                
                <div className="main-nav">
                    <div className='web-width' dangerouslySetInnerHTML={{ __html: state.data.webMenu }}>
                                
                                
                    </div>
                    <div className="web-width "  dangerouslySetInnerHTML={{ __html: state.data.megaMenus }}>
                        
                    </div>
                </div>
                
            </div>
            <div className='web-width'>
                {page.title.get!=='' ?<IonListHeader className="heading ion-hide-md-down"> {page.title.get} </IonListHeader>:''}
            </div>
        </div>
    );
}


const WebMenu =(props:any)=>{
    return (
        <>
            <ul className={(props.isParent)?"child":""}>
                {props.Menu.map((node:any,index:number)=>(
                    <li className={`parent level${props.level}`} key={index} ><Link to={`/${node.link}`}>{node.name}</Link>
                        {node.childrens ? <WebMenu Menu={node.childrens} isParent={node.childrens && node.childrens.length>0} level={props.level+1} /> : ''}
                    </li>
                ))}
            </ul>
        </>
    )
}


export default WebHeader;