import React from 'react';
import {IonLoading} from '@ionic/react';
import {usePageContext} from '../store';
import ProductSearch from './modals/ProductSearch';
import ModalImageViewer from './modals/ModalImageViewer';

const CommonElements:React.FC =()=>{

    const page = usePageContext();

    return (
        <>  
            <IonLoading
                isOpen={page.loading.status}
                onDidDismiss={() => page.loading.hide()}
                message={'Please wait...'}
            />
            <ProductSearch/>
            <ModalImageViewer />
        </>
    )
}

export default CommonElements;