import React,{useCallback} from 'react';
import { logOut } from 'ionicons/icons';
import { user } from '../services/user';
type user ={
    isLogin:boolean,
    uid:string,
    role:string,
    name:string,
}
type cart={
    pid:string,
    name:string,
    quantity:number,
    price:number
}[];

interface store{
    user:user,
    cart:cart,
    data:{
        categories:[],
        ticker:{},
        banner:[],
        featuredProducts:[],
        isLoaded:boolean,
        theme:{},
        webMenu:string,
        megaMenus:string
    },
    modal:{
        showSearch:boolean;
    },
    modalProdImageViewer:{
        source:any,show:boolean
    }
}
export const initStore:store = {
    user:{
        isLogin:false,
        uid:'',
        role:'guest',
        name:'',
    },
    cart:[],
    data:{
        categories:[],
        ticker:{},
        banner:[],
        featuredProducts:[],
        isLoaded:false,
        theme:{},
        webMenu:'',
        megaMenus:''
    },
    modal:{
        showSearch:false
    },
    modalProdImageViewer:{
        source:{},show:false
    }
}

const Reducer = (state:any,action:any)=>{
    switch(action.type){
        case "login":
            return setLoginState(state,action.payload);
        case "logout":
            return setLogoutState(state);
        case "siteData":
            return setSiteData(state,action.payload);
        case "updateCart":
            return updateCart(state,action.payload);    
        case "search": // search modal
            let newState = {...state};
            newState.modal.showSearch = action.payload;
            return newState;
        case "modalProdImageViewer": // search modal
            return {...state,modalProdImageViewer:action.payload};
        default:
            return state;
    }    
}
const setSiteData = (state:store,payload:any)=>{
    let newState = {...state,data:payload};
    newState.data.isLoaded = true;
    console.log('state')
    console.log(newState)
    // store site data to local storage
    localStorage.setItem('siteData', JSON.stringify(payload));

    return newState;
}


/**
 * set use login state
 * @param state 
 * @param user 
 */
const setLoginState = (state:store,payload:any)=>{
    let newState = {...state};
    newState.user.isLogin = true;
    newState.user.uid = payload.uid;
    newState.user.role = payload.role;
    newState.user.name = payload.name;
    newState.cart = payload.cart;
    console.log('--------user------');
    console.log(newState);
    return newState;
}

const setLogoutState = (state:store):store=>{
    let newState = {...state};
    newState.user.isLogin = false;
    newState.user.uid = '';
    newState.user.role = 'guest';
    newState.cart = [];

    //localStorage.clear();
    user.signOut();

    return newState;
}

const updateCart = (state:store,payload:any)=>{
    let newState = {...state};
    newState.cart = payload;
    // sort list
    //if(newState.cart==null)
    //    newState.cart = [];
    //newState.cart.sort((p1:any,p2:any)=>p1.name.localeCompare(p2.name));
    return newState;
}


/*const logout = (state:store,payload:any)=>{
    let newState = {...state};
    newState.user.isLogin = false;
    newState.user.role = 'guest';
    
    user.signOut();
}*/

export default Reducer;
