import swal from 'sweetalert';
import moment from 'moment';
const {SERVER_ENDPOINT} = require('../config');
/**
 * Standard return message type
 */
enum msgType {
    SUCCESS,
    ERROR,
    WARNING
}

/**
 * swal html message 
 * @param html 
 */
const HtmlContent = (html:string):any =>{
    var content = document.createElement("span");
    content.innerHTML=`${html}`
    return content;
}

const util = {
    /**
     * Alerts
     */
    alert:{
        success:(title:string,message:string,callback?:any)=>{
            const content = HtmlContent(message);
            swal({title:title,content:content, icon:"success",closeOnClickOutside:false,closeOnEsc:false}).then((value) => {
                if(typeof callback === "function")
                    callback(value);
            });
        },
        warning:(title:string,message:string,callback?:any)=>{
            const content = HtmlContent(message);
            swal({title:title,content:content, icon:"warning",closeOnClickOutside:false,closeOnEsc:false,dangerMode: true}).then((value)=>{
                if(typeof callback === "function")
                    callback(value);
            });
        },
        error:(title:string,message:string,callback?:any)=>{
            const content = HtmlContent(message);
            swal({title:title,content:content, icon:"error",closeOnClickOutside:false,closeOnEsc:false,dangerMode: true}).then((value)=>{
                if(typeof callback === "function")
                    callback(value);
            });
        },
        info:(title:string,message:string,callback?:any)=>{
            const content = HtmlContent(message);
            swal({title:title,content:content, icon:"info",closeOnClickOutside:false,closeOnEsc:false}).then((value)=>{
                if(typeof callback === "function")
                    callback(value);
            });
        },
        // base of u.message return 
        show:(title:string,res:any,callback?:any)=>{
            if(res){
                if(res.success)
                util.alert.success(title,res.message,callback);
                else if(res.error)
                    util.alert.error(title,res.message);
                else if(res.warning)
                    util.alert.warning(title,res.message);    
            }
        }
    },
    /**
     * confirmation method
     */
    confirm:(title:string,message:string,falseBtnText:string,trueBtnText:string,callback:any)=>{
        const content = HtmlContent(message);
        swal({
            title: title,
            //text: message,
            content:content,
            //icon: "warning",
            buttons: {
                false:{
                    text:falseBtnText,
                    value:false
                },
                true:{
                    text:trueBtnText,
                    value:true
                }
            },
            dangerMode: true,
            closeOnClickOutside:false,
            closeOnEsc:false
          })
          .then((val) => {
            if (val) {
              callback(val);
            } else {
                callback(val);
            }
          });
    },
    
    /**
     * Standard return type
     */
    msgType:msgType,
    msg:(type:msgType,message:string,data?:any)=>{
        
        let error:boolean = (type=== msgType.ERROR);
        let warning:boolean = (type=== msgType.WARNING);
        let success:boolean = (type=== msgType.SUCCESS);
        return {error,success,warning,message,data};
    },
    msgSystemError:()=>{
        return util.msg(util.msgType.ERROR,"System Error, Try later.");
    },


    /**
     * helper functions
     */
    helper:{
        sanitizeText:(text:string)=>{
            return text.replace(/\s+/ig, ' ').replace(/\//g, '').replace(/[^aA-zZ|0-9|\-|\s]/g,'').toLowerCase();
        },
        sanitizeSentance:(text:string)=>{
            return text.replace(/\s+/ig, ' ').replace(/[^aA-zZ|0-9|\-|\s]/g,'').toLowerCase();
        },
        // check json is empty
        isEmpty:(obj:any)=>{
            for(var key in obj) {
                if(obj.hasOwnProperty(key))
                    return false;
            }
            return true;
        },
        
        extractSearchUrl:(link:string)=>{
            //link = '/products/gemstones/shungite/angel/angel 1'
            let params = link.replace(/(\/?products\/?)/ig,'').split('/');
            console.log(params)
            // clear - char for empty
            params.forEach((k,index)=>{
                if(k === '-') params[index] = '';
            })

            if(params.length > 6){
                return{
                    class:(params[0]? params[0].toLowerCase() :''),
                    category:(params[1]?params[1].toLowerCase():''),
                    type:(params[2]?params[2].toLowerCase():''),
                    subType:(params[3]?params[3].toLowerCase():''),
                    
                    featured:(params[4]?params[4]:'0'),
                    closeout:(params[5]?params[5]:'0'),
                    newarrival:(params[6]?params[6]:'0'),    
                    page:(params[7]?params[7]:'1'),
                }
            }else if (params.length > 5){ // typeDesc
                return { 
                    class:(params[0]? params[0].toLowerCase() :''),
                    category:(params[1]?params[1].toLowerCase():''),
                    type:(params[2]?params[2].toLowerCase():''),
                    subType:(params[3]?params[3].toLowerCase():''),
                    typeDesc:(params[4]?params[4].toLowerCase():''),
                    page:(params[5]?params[5]:'1'),
                    
                }
            }else{
                return {
                    class:(params[0]? params[0].toLowerCase() :''),
                    category:(params[1]?params[1].toLowerCase():''),
                    type:(params[2]?params[2].toLowerCase():''),
                    subType:(params[3]?params[3].toLowerCase():''),
                    page:(params[4]?params[4]:'1'),
                }
            }
        },
        reformUrl:(link:string,page:number)=>{
            let params:any = util.helper.extractSearchUrl(link)
            params.page = page;

            let strArr = [];
            for(let k in params){
                strArr.push((params[k]=="")?'-':params[k])
            }
            return strArr.join("/")
        },
    
    },

   
    format:{
        currency:(val:number)=>{
            if(val===undefined || val=== 0){
                return '';
            }else
                return `$${ parseFloat(val.toString()).toFixed(2)}`;
        },
        datetime:(dt:string)=>{
            return moment(dt).format('MMMM Do YYYY, h:mm:ss a')
        }
    },
    validate:{
        isEmpty:(val:string)=>{
            if(val===null || val == undefined) return true;
            else if(val.trim() ==='') return true;
            return false;
        }
    },
    
    isMobile:()=>{
        return (window.innerWidth <= 640)?true:false;
    }
    ,
    getImage:(path:string)=>{
        //return `https://quasargems.com${path}`;
        return `${SERVER_ENDPOINT}${path}`;
    }
}


export default util;