/**
 * frontend site data handling service
 */
import React from 'react';
import u from './util';
import { isArray } from 'util';
import {selectiveProducts} from '../Constants';
import { flag } from 'ionicons/icons';

import client from './client';

const get = async(path:string,params:any)=>{
    let res:any = await client.get(path,params);
    return res;
}
const post = async(path:string,params:any)=>{
    let res:any = await client.post(path,params);
    return res;
}
const upload = async(type:string,formData:any)=>{
    let res:any = await client.upload(type,formData);
    return res;
}

export interface AddressBookType {
    Billing:{
        FirstName:string,
        LastName:string,
        BusinessName:string,
        Country:string,
        Street:string,
        City:string,
        State:string,
        Zip:string,
        Phone:string,
        Email:string
    },
    Shipping:{
        FirstName:string,
        LastName:string,
        BusinessName:string,
        Country:string,
        Street:string,
        City:string,
        State:string,
        Zip:string,
        Phone:string,
        Email:string
    }
}

const AddressBookBlank:AddressBookType = {
    Billing:{FirstName:'',LastName:'',City:'',BusinessName:'',Country:'',Email:'',Phone:'',State:'',Street:'',Zip:''},
    Shipping:{FirstName:'',LastName:'',City:'',BusinessName:'',Country:'',Email:'',Phone:'',State:'',Street:'',Zip:''}    
}

/*interface itemType {
    id:string,
    name:string,
    quantity:number,
    rate:number,
    total:number,
    product:any
}*/

 
export const service = {
    get,post,upload,
    AddressBookBlank
}