const axios = require('axios');
const qs =  require('qs');
const {API_ENDPOINT} = require('../config');

const client = {
    get: async(url:string,params:any)=>{
        try{
            let token = window.localStorage.getItem('token');
            let response = await axios.get(
                `${API_ENDPOINT}${url}`, 
                {params:params,headers:{'x-token':`Bearer ${token}`}}, 
            )
            return {
                error:false,
                ...response.data,
            }
        }catch(error){
            return {
                error:true,
                message:error.toString(),
            }
        }
    },
    post: async(url:string,params:any)=>{
        try{
            let token = window.localStorage.getItem('token');
            let response = await axios.post(
                `${API_ENDPOINT}${url}`, 
                params,
                {headers:{'x-token':`Bearer ${token}`}, 
            })
            return {
                error:false,
                ...response.data,
            }
        }catch(error){
            return {
                error:true,
                message:error.toString(),
            }
        }
    },
    upload: async(type:string,blob:any)=>{
        const form:any = new FormData();
        form.append('file',blob);
        form.append('type',type); // banners|products|etc
        try{
            let token = window.localStorage.getItem('token');
            let response = await axios.put(
                `${API_ENDPOINT}/upload`, 
                form,
                {
                    headers:{
                    'x-token':`Bearer ${token}`,
                    'content-type':'multipart/form-data; '
                }
            })
            return {
                error:false,
                ...response.data,
            }
        }catch(error){
            return {
                error:true,
                message:error.toString(),
            }
        }
    },

    instagramFeed: async(name:string)=>{
        try{
            let response = await axios.get(
                `https://www.instagram.com/${name}/?__a=1`
            )
            if(response){
                let data:any = [];
                if(response.data.graphql){
                    let edges:any = response.data.graphql.user.edge_owner_to_timeline_media.edges;
                   for(let k in edges){
                    data.push({
                        display_url:edges[k].node.display_url,
                        text:(edges[k].node.edge_media_to_caption.edges?edges[k].node.edge_media_to_caption.edges[0].node.text:''),
                        id:edges[k].node.id,
                        shortcode:edges[k].node.shortcode
                    });
                   } 
                }
                return data;
            }
            return response
        }catch(error){
            return {
                error:true,
                message:error.toString(),
            }
        }
    }
}

export default client;