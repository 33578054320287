import React from 'react';
import u from '../services/util';
import {service} from './';

const signIn = async(email:string,password:string)=>{
    
    let result = await service.post('auth',{email:email,pwd:password});
    if(result && result.success){
        // store token and related references to local storage
        localStorage.setItem('token',result.data.token);
        localStorage.setItem('user',JSON.stringify(result.data.user));
        return true;
    }else{
        u.alert.error("SignIn",result.message);
    }
    //console.log(result);
    /*let res = await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(function() {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return firebase.auth().signInWithEmailAndPassword(email, password);
    })
    .catch(async function(error) {
        //var errorMessage = error.message;
        // check in registratoin table
        let registration = await db.findOne("registrations",["Email","==",email]);
        if(registration){
            u.alert.error("SignIn",`Our records indicate that the Registration request has already been submitted using this email.<br/>
                                    Please contact us via email at <a href="mailto:sales.quasargems@gmail.com">sales.quasargems@gmail.com</a> or call us directly to our warehouse at <strong>(262)-436-9002</strong> if you have any questions`);
        }else{
            u.alert.error("SignIn","Invalid Email/Password");
        }
    });*/

}
    
const signOut = ()=>{
    //localStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    //firebase.auth().signOut();
}

// verify the user with token and get user related info
const authVerification = async()=>{ 
    try{
        let result = await service.post('auth/verification',{});
        if(result && result.success){
            let user:any = localStorage.getItem('user');
            if(user){
                user = JSON.parse(user);
                return {
                    isLogin:true,
                    uid:user.UserID,
                    role:user.Role.toLowerCase(),
                    name:user.Name,
                    cart:result.data
                }
            }else{
                return false
            }
        }else
            return false;
    }catch(error){
        return false;
    }
    
}

export const user = {
    signIn,signOut,authVerification
}