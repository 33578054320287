import React from 'react';
import {IonFooter,IonToolbar,IonButtons,IonButton,IonIcon,IonBadge } from '@ionic/react';
import {homeOutline,cartOutline,personOutline,logOutOutline,searchOutline, desktopOutline, logInOutline} from 'ionicons/icons';

import {usePageContext,useStoreContext} from '../store/';
import { Link } from 'react-router-dom';
import {menuController} from '@ionic/core';
import {user} from '../services/user';

const Footer:React.FC = ()=>{
    const {state,dispatch}  = useStoreContext();
    return (
        
        <IonFooter className="ion-hide-md-up footer">
              <IonToolbar>
                  <IonButtons  slot="start">
                      <IonButton shape="round"  routerLink='/' >
                          <IonIcon icon={homeOutline}/>
                      </IonButton>
                      <IonButton shape="round" onClick={()=>{dispatch({type:'search',payload:true})}}>
                          <IonIcon icon={searchOutline}/>
                      </IonButton>
                      <IonButton shape="round" onClick={()=>{menuController.open("menu-cart")}}>
                          <IonIcon icon={cartOutline}/>
                        <IonBadge color="danger">{state.cart.length}</IonBadge>
                      </IonButton>
                  </IonButtons> 
                  {state.user.isLogin?
                  <IonButtons  slot="start">
                    <IonButton shape="round" routerLink='/myAccount' >
                        <IonIcon icon={personOutline}/>
                    </IonButton>
                    <IonButton shape="round" onClick={()=>{ dispatch({type:'logout'}) }}>
                        <IonIcon icon={logOutOutline}/>
                    </IonButton>
                  </IonButtons>
                  :
                  <IonButtons  slot="start">
                    <IonButton shape="round" routerLink='/registration' >
                        <IonIcon icon={desktopOutline}/>
                    </IonButton>
                    <IonButton shape="round" routerLink='/login' >
                        <IonIcon icon={logInOutline}/>
                    </IonButton>
                  </IonButtons>
                  }
              </IonToolbar>
        </IonFooter>

    );
}

export default Footer;