import React from 'react';
import {Link} from 'react-router-dom';
import {IonGrid,IonRow,IonCol,IonIcon } from '@ionic/react';
import {logoFacebook,logoInstagram,logoYoutube, locationOutline, callOutline, mailOutline, chevronForwardOutline } from 'ionicons/icons';
import {useStoreContext} from '../store/';
import u from '../services/util';
import logo from '../assets/imgs/logo-w.png';


const WebFooter:React.FC = ()=>{

    const {state,dispatch}  = useStoreContext();

    const shuffle = (array: string[]) => { 
        return array.sort(() => Math.random() - 0.5); 
    }; 
    

    return (
        
        <div className="web-footer ion-hide-md-down">
            <div className='web-width'>
                <IonGrid>
                    <IonRow>
                        <IonCol sizeMd="4">
                            <div className="logo">
                                <Link to="/"><img src={logo}></img></Link>
                                <p className='desc'>We are a wholesaler & Manufacturer of Gemstone products, Metaphysical Tools, Crystals located in Neenah, Wisconsin. We believe in providing quality products at great prices with total focus on great customer experience.</p>
                            </div>
                            <div className="info">
                                <IonIcon icon={locationOutline}></IonIcon> 409 1st Street Neenah,WI 54956
                            </div>
                            <div className="info">
                                <a href="mailto:merakigemstones@gmail.com"><IonIcon icon={mailOutline}></IonIcon> merakigemstones@gmail.com</a>
                            </div>
                            <div className="info">
                                <IonIcon icon={callOutline}></IonIcon> 920-967-4996
                            </div>
                        </IonCol>
                        {state.data  && state.data.featuredProducts  &&
                        <IonCol sizeMd="6">
                            <h4>Featured Products</h4>
                            <div className='featured'>
                            {shuffle(state.data.featuredProducts).slice(0,4).map((prod:any,index:number)=>(
                                <div key={index}>
                                    <Link to={`/product/${prod.ProductID}/${u.helper.sanitizeText(prod.Name)}`} ><img src={u.getImage(`/images/products/${prod.Image1}`)}/></Link>
                                </div>
                            ))}
                            </div>
                        </IonCol>
                        }
                        <IonCol sizeMd="2">
                            <h4>MAIN LINKS</h4>
                            <ul className='links'>
                                <li><Link to="/"><IonIcon icon={chevronForwardOutline}></IonIcon> Home</Link></li>
                                <li><Link to="/aboutus"><IonIcon icon={chevronForwardOutline}></IonIcon>About Us</Link></li>
                                <li><Link to="/contactus"><IonIcon icon={chevronForwardOutline}></IonIcon>Contact Us</Link></li>
                                {(!state.user.isLogin)? 
                                    <>
                                    <li><Link to="/login"><IonIcon icon={chevronForwardOutline}></IonIcon>Login</Link></li>
                                    <li><Link to="/registration"><IonIcon icon={chevronForwardOutline}></IonIcon>Sign Up</Link></li>
                                    </>
                                :''}
                            </ul>
                        </IonCol>
                    </IonRow> <br/>
                    <IonRow>
                        <IonCol sizeMd="6">
                            <span className="copyright">Copyright © {(new Date).getFullYear()} Meraki Gemstomes. All rights reserved.</span>
                        </IonCol>
                        <IonCol sizeMd="6" class='social'>
                            <a href="https://www.facebook.com/Meraki-Gemstones-102098801135557" target="_blank" rel="noopener noreferrer"><IonIcon icon={logoFacebook}></IonIcon></a>
                            <a href="https://www.instagram.com/merakigemstones/" target="_blank" rel="noopener noreferrer"><IonIcon icon={logoInstagram}></IonIcon></a>
                            <a href="https://www.youtube.com/channel/UCQZqZwQJcQFPgutR7B9lrzA" target="_blank" rel="noopener noreferrer"><IonIcon icon={logoYoutube}></IonIcon></a>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        </div>        

    );
}

export default WebFooter;