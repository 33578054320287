import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonRouterOutlet,
  IonButtons,
  IonBackButton,
  IonMenuButton
} from '@ionic/react';

import React, { MouseEvent, useState, useMemo, useEffect, useLayoutEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { homeOutline, peopleOutline,appsOutline,desktopOutline, logInOutline,mailOpenOutline, logOutOutline, archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, warning, logOut, playOutline, removeOutline, chevronForward, returnDownForward } from 'ionicons/icons';
//import { grid, image,help,home,radioButtonOff,laptop, easel, logoInstagram,mail, photos, menu,bookmark, albums, filing, bonfire, funnel, film, star, flame, rainy, moon, apps, logoWhatsapp, list, logIn, chatboxes, bulb, rose, brush, colorPalette, refreshCircle, settings, refresh, logOut, camera, logoYoutube, locate, download, share, move, checkmark, informationCircle, glasses, alert, pulse, logoFacebook } from 'ionicons/icons';
import {user} from '../services/user';
import {useStoreContext} from '../store/';
import {menuController} from '@ionic/core';

import './Menu.css';

// product menu
let p_menu = new Map();

const Menu: React.FC = () => {
  const location = useLocation();

  const {state,dispatch} = useStoreContext();
  const [WebMenu,setWebMenu] = useState({});
  const [Theme,setTheme] = useState<any|null>(null);

  const history = useHistory();

  useEffect(()=>{
    let menu = [{
      link:'',
      name:'Products',
      sort:0,
      childrens:state.data.webMenu
    }];
    
    //setWebMenu(menu);
    setSideTheme();
    
    document.querySelectorAll('.mobile-menu a').forEach((el:any)=>{
      let content = el.getAttribute('content');
      if(content){
          el.onclick = ()=>{
              history.push(content);
              menuController.close('menu1')
          }
      }
  });
  

  },[state])

    // theme
  const setSideTheme = ()=>{
    
    let _theme = `:root{
        --primary-color:${state.data.theme?state.data.theme.primaryColor:'#000'};
        --secondary-color:${state.data.theme?state.data.theme.secondaryColor:'#eda907'};
        --font-default:${state.data.theme?state.data.theme.fontDefault:'#808080'};
        --fonr-highlighted:${state.data.theme?state.data.theme.fontHighlight:'#e3e3e3'};
        --h1-color:${state.data.theme?state.data.theme.h1:'red'};
        --h2-color:${state.data.theme?state.data.theme.h2:'red'};
        --h4-color:${state.data.theme?state.data.theme.h4:'#eda907'};
        --h5-color:${state.data.theme?state.data.theme.h5:'red'};

        --main-menu-bg:${state.data.theme?state.data.theme.mainMenuBg:'#eda907'};
        --main-menu-bg-hover:${state.data.theme?state.data.theme.mainMenuBgHover:'#000'};
        --main-menu-font:${state.data.theme?state.data.theme.mainMenuFont:'#000'};
        --main-menu-font-hover:${state.data.theme?state.data.theme.mainMenuFontHover:'#fff'};
        --child-menu-bg:${state.data.theme?state.data.theme.childMenuBg:'#eda907'};
        --child-menu-bg-hover:${state.data.theme?state.data.theme.childMenuHoverBg:'#000'};
        --child-menu-font:${state.data.theme?state.data.theme.childMenuFont:'#000'};
        --child-menu-font-hover:${state.data.theme?state.data.theme.childMenuFontHover:'#fff'};
        --child-menu-divider-line:${state.data.theme?state.data.theme.childMenuDividerLine:'#e1e1e1'};

        --button-bg:${state.data.theme?state.data.theme.buttonBg:'#eda907'};
        --button-hover-bg:${state.data.theme?state.data.theme.buttonHoverBg:'#fff'};
        --button-font:${state.data.theme?state.data.theme.buttonFont:'#eda923'};
        --button-hover-font:${state.data.theme?state.data.theme.buttonHoverFont:'#000'};
                
    }`;
    setTheme(_theme);
    
  }

  /*const closeAllMenuItems = ()=>{
    if(Array.from(p_menu).length){
      p_menu.forEach((val:boolean,key:string)=>{
        let child_of_node:any = document.querySelector('.child_of_'+key);
        if(child_of_node){
          child_of_node.style.display = 'none';
        }
        let parent_node:any = document.querySelector('#parent_of_'+key);
        if(parent_node){
          parent_node.classList.remove('on');
        }

      });
      p_menu.clear();
    }
  }*/
   

  return (
    <>
      {Theme?<style>{Theme}</style>:''}
      
      <IonMenu side="start" contentId="main" type="overlay" menuId="menu1" /*onIonDidClose={closeAllMenuItems}*/ >
        <IonHeader className="head">
            <div className="enappd-header">
                <div className="titleHeader">Meraki Gemstones</div>
            </div>
        </IonHeader>
        <IonContent>
          
            <div className='mobile-menu' dangerouslySetInnerHTML={{ __html: state.data.mobileMenu }}>
              
            </div>
  
            <IonList id="inbox-list">
            
              <IonMenuToggle autoHide={false}>
                <IonItem 
                    className=''
                    routerLink='/' 
                    routerDirection="none" 
                    lines="none" 
                    detail={false}
                    >
                    <IonIcon slot="start" icon={homeOutline} />
                    <IonLabel>Home</IonLabel>
                </IonItem>
              </IonMenuToggle>
              <IonMenuToggle autoHide={false}>
                <IonItem 
                    className={state.user.isLogin?'':'ion-hide'}
                    routerLink='/admin' 
                    routerDirection="none" 
                    lines="none" 
                    detail={false}
                    >
                    <IonIcon slot="start" icon={peopleOutline} />
                    <IonLabel>Admin</IonLabel>
                </IonItem>
            
                <IonItem 
                    className='' 
                    routerLink='/aboutus' 
                    routerDirection="none" 
                    lines="none" 
                    detail={false}
                    >
                    <IonIcon slot="start" icon={peopleOutline} />
                    <IonLabel>About Us</IonLabel>
                </IonItem>
            
                <IonItem style={{'display':'none'}}
                    className={state.user.isLogin?'':'ion-hide'}
                    routerLink='/quickOrder' 
                    routerDirection="none" 
                    lines="none" 
                    detail={false}
                    >
                    <IonIcon slot="start" icon={peopleOutline} />
                    <IonLabel>Quick Order</IonLabel>
                </IonItem>
            
                <IonItem 
                    className={!state.user.isLogin?'':'ion-hide'}
                    routerLink='/registration' 
                    routerDirection="none" 
                    lines="none" 
                    detail={false}
                    >
                    <IonIcon slot="start" icon={desktopOutline} />
                    <IonLabel>Sign Up / Registration</IonLabel>
                </IonItem>
            
                <IonItem 
                    className={!state.user.isLogin?'':'ion-hide'}
                    routerLink='/login' 
                    routerDirection="none" 
                    lines="none" 
                    detail={false}
                    >
                    <IonIcon slot="start" icon={logInOutline} />
                    <IonLabel>Login</IonLabel>
                </IonItem>
            
                <IonItem 
                    className=''
                    routerLink='/contactus' 
                    routerDirection="none" 
                    lines="none" 
                    detail={false}
                    onClick={()=>{user.signOut()}}
                    
                    >
                    <IonIcon slot="start" icon={mailOpenOutline} />
                    <IonLabel>Contact Us</IonLabel>
                </IonItem>
            
                <IonItem 
                    className={state.user.isLogin?'':'ion-hide'}
                    routerLink='#' 
                    routerDirection="none" 
                    lines="none" 
                    detail={false}
                    onClick={()=>{  dispatch({type:'logout'})  }}
                    
                    >
                    <IonIcon slot="start" icon={logOutOutline} />
                    <IonLabel>Logout</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonList>
           
            
      </IonContent>
      </IonMenu>
            
    </>
  );
};




export default Menu;
