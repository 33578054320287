import React, { useEffect, useState, useMemo, useLayoutEffect } from 'react';
import logo from '../../assets/imgs/weblogo.png';

import './index.css';
import { useStoreContext } from '../../store';

interface propType {
    
    children:React.ReactChildren
}

const Animation:React.FC<propType> = (props)=>{

    const {state,dispatch} = useStoreContext();

    useEffect(()=>{
        setTimeout(() => {
            let el:any = document.querySelector('.loader-wrapper');
            if(el){
                el.style.display='none';
            }
        }, 800);
    },[state.data.isLoaded])

    return (
        <>
        
            <div className={`loader-wrapper `}>
                <div className="loader-logo">
                    <img src={logo}/>
                </div>
                <div className="container">
                    <div>
                        <div className="loader"></div>
                        <label>loading...</label>
                    </div>
                </div>
            </div>
        

        {!state.data.isLoaded?'':props.children}
            
        </>
    )
}

export default Animation;