import React from 'react';
import {IonHeader,IonToolbar,IonButtons,IonMenuButton,IonTitle, IonIcon } from '@ionic/react';

import {usePageContext} from '../store';
import { callOutline, mailOpenOutline } from 'ionicons/icons';

const Header:React.FC = ()=>{
    const page = usePageContext();
    return (
        <>
        <IonHeader className="ion-hide-md-up header">
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton className="btn-menu"  />
                </IonButtons>
                <IonTitle>{page.title.get}</IonTitle>
            </IonToolbar>
            <div className="mob-info-bar">
                <span className="info-box"><span className="info-box-icon"><IonIcon icon={callOutline}/></span> 920-967-4996</span>
                <span className="info-box"><span className="info-box-icon"><IonIcon icon={mailOpenOutline}/></span> <a href="mailto:sales.merakigemstones@gmail.com">merakigemstones@gmail.com</a></span>
            </div>
        </IonHeader>
        
        </>

    );
}

export default Header;