import React,{createContext,useContext,useState,useReducer, useEffect} from 'react';

import Reducer,{initStore} from './Reducer';
// UI animation - site loader
import SiteInitializer from '../components/SiteInitializer/';

// site data service for getting data
import {service} from '../services/';
import { user } from '../services/user';

const StoreContext = createContext<any>({});
const PageContext = createContext<any>(null);

const Store:React.FC<any> = (props)=>{

    const [state,dispatch] = useReducer(Reducer,initStore);
    //const [isSiteInitialized,setIsSiteInitialized]  = useState(false);
    // page related states
    // accessable by page obj
    const [pageTitle,setPageTitle] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const page = {
        title:{
            get:pageTitle,
            set:setPageTitle
        },
        loading:{ 
            status:showLoading, 
            show:()=>{setShowLoading(true)},
            hide:()=>{setShowLoading(false)}
        }
    }

    const initializeSiteDate = async()=>{
        
        // load site data from local storage if any
        let siteData = localStorage.getItem('siteData');
        if(siteData){
            siteData = JSON.parse(siteData);
            dispatch({type:'siteData',payload:siteData});
        }
        
        // check auth
        let payload:any = await user.authVerification();
        if(payload)
            dispatch({type:"login",payload:payload});
        else
            dispatch({type:"logout"});
        
        // fetch updated site data
        const result = await service.get('dcontent/get',{key:'cache'});
        if(result && result.success){
            dispatch({type:'siteData',payload:result.data.Content});
        }
        
        //setTimeout(()=>{
            //setIsSiteInitialized(true);
        //},200)
    }

    useEffect(()=>{
        initializeSiteDate();
    },[])
            

    return (
        <StoreContext.Provider value={{state,dispatch}}>
            <PageContext.Provider value={page}>
                <SiteInitializer children={props.children}/>
            </PageContext.Provider>
        </StoreContext.Provider>
    )
}

export const usePageContext = ()=> useContext(PageContext);
export const useStoreContext = ()=> useContext(StoreContext);

export default Store;