import React,{useState,useMemo, useEffect} from 'react';
import {IonButton, IonItem, 
    IonModal,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonContent,
    IonSearchbar,
    IonList,
    IonRippleEffect,
    IonItemDivider,
    IonCheckbox,
    IonLabel,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonCol,
    IonInput} from '@ionic/react';
import { useHistory } from 'react-router';

import {usePageContext,useStoreContext} from '../../store/';

const ProductSearch:React.FC<any> = (props)=>{
    const loc = useHistory();
    const {state,dispatch}  = useStoreContext();

    const [term,setTerm] = useState<string>('');
    const [OrderByRate,setOrderByRate] = useState<string>('asc');
    
    const handlerChangeTerm = (e:any)=>{
        setTerm( e.target.value.toLowerCase() );
    }

    const handlerChangeRateOrder = (e:any)=>{
        setOrderByRate( e.detail.value.toLowerCase() );
    }

    const searchHandler=(e:any)=>{
        let query = term.replace(/[^aA-zZ|0-9|\s\-]/g,'').toLowerCase();
        if(query == '') query = '-';
        let url = `/search/${query}/${OrderByRate}`;
        loc.push(url);
        dispatch({type:'search',payload:false});
    }
    
    return (
        <IonModal isOpen={state.modal.showSearch} backdropDismiss={false}>
            <IonHeader >
                <IonToolbar>
                    <IonTitle>Search</IonTitle>
                    <IonButtons slot="end">
                        <IonButton slot="end" onClick={()=>{dispatch({type:'search',payload:false}); }}>Close</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="search-modal">
                <form className="form">
                    <IonSearchbar placeholder="Enter search term" onIonChange={handlerChangeTerm}></IonSearchbar>
                    
                    <IonList>
                        <IonRadioGroup onIonChange={handlerChangeRateOrder} value={OrderByRate}>
                            By Price: <br/>
                            <IonRow>
                                <IonCol>
                                    <IonItem> 
                                        <IonLabel className="font-14">Low to High</IonLabel>
                                        <IonRadio value="asc" />
                                    </IonItem>        
                                </IonCol>
                                <IonCol>
                                    <IonItem> 
                                        <IonLabel className="font-14">High to Low </IonLabel>
                                        <IonRadio value="desc" />
                                    </IonItem>        
                                </IonCol>
                            </IonRow>
                        </IonRadioGroup>
                    </IonList>
                    <IonList>
                        <IonItem>
                            <IonButton slot="end" onClick={searchHandler}>Search</IonButton>
                        </IonItem>
                    </IonList>
                </form>
            </IonContent>
        </IonModal>  
    )
}

export default ProductSearch;