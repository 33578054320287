import React, { useState, useEffect, useMemo } from 'react';
import YouTube from 'react-youtube';

import VideoPlayer from './VideoPlayer';
import { IonIcon } from '@ionic/react';
import { logoYoutube, closeCircle } from 'ionicons/icons';
import u from '../../services/util';
import ImageNotAvailable from '../../assets/not-available.jpg';

import {usePageContext,useStoreContext} from '../../store';

export enum ViewerSourceType {
    Image='Image',
    Video='Video'
}
export type ViewerSource = {
    src:string,
    type: ViewerSourceType
}[]


const ModalImageViewer:React.FC<any> = (props:any)=>{
    

    const [Source,setSource] = useState<ViewerSource>();
    const [playerParams,setPlayerParams] = useState({show:false,videoId:''});
    const [CurrentImage,setCurrentImage] = useState('')
    const [ModalCurrentSrc,setModalCurrentSrc] = useState({src:'',type:''})
    
    // for model only
    const {state,dispatch} = useStoreContext();
    const [ShowModalViewer,setShowModalViewer] = useState(false);
    

    const loadResources = (source:any)=>{
        let count = 0;
        for(let s of source){
            if(count == 0 && s.type == ViewerSourceType.Image){
                if(s.src!=null){
                    setCurrentImage((s.src)?(u.getImage(`/images/products/${s.src}`)):ImageNotAvailable);
                    setModalCurrentSrc({src:s.src?(u.getImage(`/images/products/${s.src}`)):ImageNotAvailable,type:s.type});
                }
            }
            count++;

            if(s.type == ViewerSourceType.Video){
                playerParams.videoId = s.src;
            }
        }
    }
    
    useEffect(()=>{
        setShowModalViewer(state.modalProdImageViewer.show);
        if(state.modalProdImageViewer.show)
            setSource(state.modalProdImageViewer.source);
    })
    
    useMemo(()=>{
        if(Source != undefined && Source.length > 0){
            loadResources(Source);
        }
    },[Source])

    const handlerHideModal = ()=>{
        dispatch({type:"modalProdImageViewer",payload:{source:[],show:false}});
    }

    const opts:any = {
        width: '100%',
        playerVars: {
          autoplay: 0,
        }
    }

    return (
        <>
            <div className="img-viewer-modal img-viewer ion-hide-md-down" style={{display:(ShowModalViewer?'':'none')}}>
                <div className="modal-container">
                    <IonIcon className="close" icon={closeCircle} onClick={handlerHideModal}></IonIcon>
                    <div className="thumbnails-list">
                        {Source && Source.map((src:any,index:number)=>(
                            <div  key={index}>
                                {src.type == ViewerSourceType.Image?
                                <img src={((src.src)?(u.getImage(`/images/products/${src.src}`)):ImageNotAvailable)} onClick={()=>{
                                    setModalCurrentSrc({src:(src.src)?(u.getImage(`/images/products/${src.src}`)):ImageNotAvailable,type:src.type});
                                }} />
                                :
                                <img src={`https://i.ytimg.com/vi/${src.src}/hqdefault.jpg`} onClick={()=>{
                                    setModalCurrentSrc({src:src.src,type:src.type})
                                }}/>
                                }
                            </div>
                        ))}        
                    </div>
                    <div className='main-screen'>
                        <div className='content'>
                        {ModalCurrentSrc && ModalCurrentSrc.type == ViewerSourceType.Image?
                            <img src={(ModalCurrentSrc.src?ModalCurrentSrc.src:ImageNotAvailable)}/>
                            :
                            (ShowModalViewer)?<YouTube videoId={ModalCurrentSrc.src} opts={opts}/>:''
                        }
                        </div>
                    </div>
                    
                    
                </div>
            </div>
            
            <VideoPlayer params={playerParams} hide={()=>{ setPlayerParams({...playerParams,show:false}) }} />
        </>
    )
}

export default ModalImageViewer;