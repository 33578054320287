import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonRouterOutlet,
  IonButton
} from '@ionic/react';

import React, { MouseEvent, useState, useEffect } from 'react';
import { useLocation,withRouter,useHistory } from 'react-router-dom';
import { trashOutline } from 'ionicons/icons';
import {menuController} from '@ionic/core';
import {user} from '../services/user';
import {useStoreContext,usePageContext} from '../store';
import u from '../services/util';
import {service} from '../services/';
import ImageNotAvailable from '../assets/not-available.jpg';

const CartMenu: React.FC = () => {
  const history = useHistory();
  const page = usePageContext();
  const {state,dispatch} = useStoreContext();
  const [SubTotal,setSubTotal] = useState(0)

  useEffect(()=>{
    if(state.cart && state.cart.length > 0){
      const total = state.cart.reduce((total:number,item:any)=>{
        return total + parseFloat(item.Total);
      },0);
      setSubTotal(total);
    }else{
      setSubTotal(0)
    }
  },[state])

  // remove item from cart
  const removeItem = async(pid:string)=>{
    page.loading.show();
    state.cart = state.cart.filter((item:any)=>{
        return item.ProductID != pid;
    });
    await service.post('cart/remove',{pid:pid});
    dispatch({type:'updateCart',payload:state.cart});
    page.loading.hide();
  }

  return (
    <>
      <IonMenu side="end" contentId="main" type="overlay" menuId="menu-cart">
        <IonHeader class="head">
            <div className="enappd-header">
                <div className="titleHeader">Shopping Cart</div>
            </div>
        </IonHeader>
        <IonContent className="cart-menu">
          {state.cart.length > 0 && state.cart.map((item:any, index:number) => (
            
              <div className="item-list" key={index}>
                <img src={(item.Image1?(u.getImage(`/images/products/${item.Image1}`)):ImageNotAvailable)}/>
                <p>{item.Name} {item.PricingTypePrice>0?<span className="price-type">{item.PriceType}</span>:''} </p>
                
                <span>Quantity: <strong>{item.Quantity}</strong></span>
                <IonIcon icon={trashOutline} onClick={()=>{removeItem(item.ProductID)}}/>
              </div>
            
          ))}
          {state.cart.length > 0?
          <>
            <div className="sub-total">
              <IonLabel>Sub Total</IonLabel>
              {u.format.currency(SubTotal)}
            </div>
            
            <IonButton expand="block" 
              onClick={()=>{
                  history.push('/cart');
                  menuController.close("menu-cart")
                }} 
                color="primary"
                >View Cart  
            </IonButton>
              
            <IonButton expand="block" 
              onClick={()=>{
                history.push('/checkout');
                menuController.close("menu-cart")
              }} 
              color="success">CheckOut
            </IonButton>
          </>
          :'No Item to display'}
        </IonContent>
      </IonMenu>
    </>
  );
};

export default CartMenu;
